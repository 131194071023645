// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blogs-js": () => import("./../../../src/pages/blogs.js" /* webpackChunkName: "component---src-pages-blogs-js" */),
  "component---src-pages-components-blog-list-js": () => import("./../../../src/pages/components/BlogList.js" /* webpackChunkName: "component---src-pages-components-blog-list-js" */),
  "component---src-pages-components-blog-preview-tile-js": () => import("./../../../src/pages/components/BlogPreviewTile.js" /* webpackChunkName: "component---src-pages-components-blog-preview-tile-js" */),
  "component---src-pages-components-customer-logos-js": () => import("./../../../src/pages/components/CustomerLogos.js" /* webpackChunkName: "component---src-pages-components-customer-logos-js" */),
  "component---src-pages-components-descriptive-section-js": () => import("./../../../src/pages/components/DescriptiveSection.js" /* webpackChunkName: "component---src-pages-components-descriptive-section-js" */),
  "component---src-pages-components-feature-js": () => import("./../../../src/pages/components/Feature.js" /* webpackChunkName: "component---src-pages-components-feature-js" */),
  "component---src-pages-components-hero-js": () => import("./../../../src/pages/components/Hero.js" /* webpackChunkName: "component---src-pages-components-hero-js" */),
  "component---src-pages-components-skills-js": () => import("./../../../src/pages/components/Skills.js" /* webpackChunkName: "component---src-pages-components-skills-js" */),
  "component---src-pages-components-testimonials-js": () => import("./../../../src/pages/components/Testimonials.js" /* webpackChunkName: "component---src-pages-components-testimonials-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}


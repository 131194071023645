module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Gatsby Starter Tailwind CSS","short_name":"Gatsby Starter Tailwind CSS","start_url":"/","background_color":"#fff","theme_color":"#38b2ac","icon":"static/icon.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"29298f7d8117a56ec47726737cf5b463"},
    },{
      plugin: require('../node_modules/gatsby-source-prismic/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"techwondoe","accessToken":"MC5ZRkcyM2hBQUFDTUFCMWRs.77-9Hgzvv71b77-977-977-9SFrvv73vv73vv70p77-977-9Be-_vQEQ77-977-9BO-_vTIJXVDvv73vv71FAQ","fetchLinks":[],"schemas":{"testimonial":{"Main":{"client_name":{"type":"StructuredText","config":{"single":"heading1, heading2, heading3, heading4, heading5, heading6","label":"Client_name"}},"client_role":{"type":"StructuredText","config":{"single":"heading1, heading2, heading3, heading4, heading5, heading6","label":"client_role"}},"client_image":{"type":"Image","config":{"constraint":{},"thumbnails":[],"label":"client_image"}},"content":{"type":"StructuredText","config":{"multi":"paragraph, preformatted, heading1, heading2, heading3, heading4, heading5, heading6, strong, em, hyperlink, image, embed, list-item, o-list-item, rtl","label":"content"}}}}},"lang":"*","prismicToolbar":true,"imageImgixParams":{"auto":"compress,format","fit":"max","q":50},"imagePlaceholderImgixParams":{"w":100,"blur":15,"q":50},"typePathsFilenamePrefix":"prismic-typepaths---gatsby-source-prismic-test-site"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
